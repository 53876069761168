import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SNACKBAR_DISPLAY_DURATION } from "src/constants";

@Injectable({
  providedIn: "root"
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  defaultConfig: MatSnackBarConfig = {
    duration: SNACKBAR_DISPLAY_DURATION
  };

  openWithMessage(
    message: string,
    action?: string,
    config: MatSnackBarConfig = this.defaultConfig
  ): void {
    this.snackbar.open(message, action, config);
  }

  serverErrorPrompt(input?: string): void {
    this.snackbar.open("Server Error! Please try again later. " + input, "Dismiss");
  }
}
