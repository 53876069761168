import { CreateLayerSetConfig } from "../../app/blades/explore/models/layer.model";
import { FilterSetGroup } from "../../app/blades/explore/models/filter.model";
import { TableColumnsObject } from "src/app/blades/explore/models/poi-table.model";

export interface PlatformConfig {
  advancedDeepDive: boolean;
  apiUrl: string;
  barredPOIs: string[];
  baseUrl: string;
  defaultFilterSetConfig: FilterSetGroup[];
  defaultGeographyAreaThreshold: number;
  defaultTableConfig?: TableColumnsObject;
  elasticUrl: string;
  enableAdvancedSearch: boolean;
  enableDSMapsDashboard: boolean;
  enableGeographyMultiSelect: boolean;
  enableRaiseAnIssue?: boolean;
  enableRankByFeature: boolean;
  enableRegistration: boolean;
  enableShowValuesForFeature: boolean;
  hasClusters: boolean;
  layerSetConfig: CreateLayerSetConfig[];
  mapboxBaseMapStyle?: "dark" | "light" | "satellite" | "streets";
  poiRelationshipConfig: "poi-grid" | "poi-shape";
}

export const layerColors: number[][] = [
  [128, 128, 128],
  [61, 90, 241],
  [255, 0, 110],
  [60, 180, 75],
  [255, 225, 25],
  [240, 130, 48],
  [145, 30, 180],
  [250, 60, 240],
  [180, 255, 50],
  [220, 190, 255],
  [255, 250, 200],
  [170, 110, 40],
  [128, 0, 0],
  [170, 255, 195],
  [250, 190, 212],
  [128, 128, 0],
  [255, 215, 180],
  [0, 0, 128],
  [255, 255, 255],
  [224, 55, 55],
  [122, 181, 255],
  [179, 98, 98],
  [198, 253, 187],
  [187, 222, 251],
  [100, 181, 246],
  [212, 225, 87],
  [255, 204, 128],
  [239, 108, 0],
  [229, 57, 53],
  [188, 170, 164],
  [24, 255, 255],
  [255, 255, 0],
  [51, 105, 30],
  [0, 131, 143],
  [173, 20, 87],
  [255, 128, 171]
];
