import { Directive, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

/**
 * This directive disables form controls dynamically inside a
 * reactive form group
 *
 * Usage:
 * <input formControlName="name" [appDisableFormControl]="condition" />
 */

@Directive({
  selector: "[appDisableFormControl]"
})
export class DisableFormControlDirective {
  @Input() set appDisableFormControl(condition: boolean) {
    const action = condition ? "disable" : "enable";
    if (this.ngControl.control) this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {}
}
