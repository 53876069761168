import { Action } from "@ngrx/store";
import { User } from "../../models/user.model";
import { HttpErrorResponse } from "@angular/common/http";

export interface LoginInput {
  email: string;
  password: string;
}

export enum AuthActionTypes {
  LOGIN_START = "[AUTH] Start login",
  LOGIN_SUCCESS = "[AUTH] Login success",
  LOGIN_FAILURE = "[AUTH] Login failure",
  RESET_AUTH = "[AUTH] Reset auth",
  LOGOUT_START = "[AUTH] Logout start",
  LOGOUT_END = "[AUTH] Logout end",
  SET_REDIRECT_URL = "[AUTH] Set redirect URL"
}

export class LoginStartAction implements Action {
  readonly type = AuthActionTypes.LOGIN_START;
  constructor(public payload: string) {}
}

export class LoginSuccessAction implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: User) {}
}

export class LoginFailureAction implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetRedirectUrl implements Action {
  readonly type = AuthActionTypes.SET_REDIRECT_URL;
  constructor(public payload: string) {}
}

export class ResetAuthAction implements Action {
  readonly type = AuthActionTypes.RESET_AUTH;
}

export class LogoutStartAction implements Action {
  readonly type = AuthActionTypes.LOGOUT_START;
}

export class LogoutEndAction implements Action {
  readonly type = AuthActionTypes.LOGOUT_END;
}

export type AuthAction =
  | LoginStartAction
  | LoginSuccessAction
  | LoginFailureAction
  | ResetAuthAction
  | LogoutStartAction
  | LogoutEndAction
  | SetRedirectUrl;
