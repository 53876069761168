import { AuthAction, AuthActionTypes } from "../actions/auth.actions";
import { User } from "../../models/user.model";
import { HttpErrorResponse } from "@angular/common/http";

export interface AuthState {
  user?: User;
  loading: boolean;
  error: HttpErrorResponse;
  isLoggedIn: boolean;
  redirectUrl: string;
}

const initialAuthState: AuthState = {
  user: null,
  loading: false,
  error: undefined,
  isLoggedIn: false,
  redirectUrl: ""
};

export function AuthReducer(state: AuthState = initialAuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN_START:
      return {
        ...state,
        loading: true
      };

    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: undefined,
        isLoggedIn: true
      };

    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload,
        isLoggedIn: false
      };

    case AuthActionTypes.RESET_AUTH:
      return initialAuthState;

    case AuthActionTypes.LOGOUT_START:
      return {
        ...state,
        loading: true
      };

    case AuthActionTypes.LOGOUT_END:
      return {
        ...state,
        user: null,
        loading: false,
        error: undefined,
        isLoggedIn: false
      };

    case AuthActionTypes.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload
      };

    default:
      return state;
  }
}
