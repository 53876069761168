import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, switchMap, catchError } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";
import {
  AuthActionTypes,
  LoginStartAction,
  LoginSuccessAction,
  LoginFailureAction,
  LogoutStartAction,
  LogoutEndAction
} from "../actions/auth.actions";
import { User } from "../../models/user.model";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackbarService } from "src/app/services/snackbar.service";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private snackService: SnackbarService
  ) {}

  @Effect() loginUser$ = this.actions$.pipe(
    ofType<LoginStartAction>(AuthActionTypes.LOGIN_START),
    switchMap(data => {
      return this.authService.loginUser(data.payload).pipe(
        map((user: User) => {
          this.snackService.openWithMessage("Signing In.. Please wait.");
          return new LoginSuccessAction(user);
        }),
        catchError((error: HttpErrorResponse) => {
          this.snackService.openWithMessage("Please Log In using an Authorized Gmail Account!");
          return of(new LoginFailureAction(error));
        })
      );
    })
  );

  @Effect() logoutUser$ = this.actions$.pipe(
    ofType<LogoutStartAction>(AuthActionTypes.LOGOUT_START),
    switchMap(() => {
      return this.authService.logoutUser().pipe(map(() => new LogoutEndAction()));
    })
  );
}
