import { Pipe, PipeTransform } from "@angular/core";
import { removeSnakeCase } from "../../../utils/removeSnakeCase";

@Pipe({
  name: "removeSnakeCase"
})
export class RemoveSnakeCasePipe implements PipeTransform {
  transform(str: string | number): string {
    if (typeof str === "string") {
      return removeSnakeCase(str);
    } else {
      return str.toString();
    }
  }
}
